<template>
    <div>
        <custom-upload-file :upload-params="uploadParams" :action="url" v-on="$listeners" />
    </div>
</template>

<script>
import CustomUploadFile from '@/components/CustomUploadFile'
import { SAFETY_MFG_URL } from '@/config'
export default {
    name: 'UploadFileModal',
    components: {
        CustomUploadFile
    },
    props: {
        bizCode: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            url: `${SAFETY_MFG_URL}/file_operate/upload/file`
        }
    },
    computed: {
        projectInfo() {
            return this.$store.getters.projectInfo
        },
        projectId() {
            return this.projectInfo.id
        },
        uploadParams() {
            return {
                bizCode: this.bizCode,
                projectId: this.projectId
            }
        }
    }
}
</script>
